import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import React, { FC } from 'react'
import { BoxesSwapper } from './BoxesSwapper'
import { getAge } from 'functions/getAge'
import { createDateFromStrings } from 'functions/date/createDateFromStrings'
import { minimumAge } from 'common/constants'

export const AnimatedAge: FC = () => {
  const { day, month, year } = useShallowEqualSelector(
    ({
      stepRegistration: {
        form: { day, month, year },
      },
    }) => ({ day, month, year })
  )

  const age = createAgeValue(day, month, year)

  return <BoxesSwapper value={age} defaultValue="18+" />
}

const createAgeValue = (day: string, month: string, year: string) => {
  const date = createDateFromStrings(day, month, year)

  if (!date) {
    return
  }

  const age = getAge(date)

  if (age < minimumAge || age > 99) {
    return
  }

  return age
}
