import { VoidHandler } from 'common/types'
import { BOX_SIZE } from './WaveBoxes.constants'
import { FPS_COUNT, OVERFLOW, SCALE } from './Wave.constants'

export class SinusoidalView {
  private phase: number = 0
  private phaseStep: number = 3 / FPS_COUNT // 0.05
  private amplitudeNormal: number = 60
  private amplitudeBig: number = 160
  private amplitudeDelta: number = 2
  private currentAmplitude: number = 60
  private isPumping: boolean = false
  private pumpsCount: number = 0
  private canvasWidth: number
  private canvasHeight: number
  private phaseBeforePump: number = 0
  private direction: 1 | -1 = 1
  private a: number = Math.PI

  private boxes: HTMLDivElement[]
  private context: CanvasRenderingContext2D
  private onPumpFinished: VoidHandler | undefined
  private lineWidth: number
  private mobile: boolean
  private interval: number

  constructor(
    canvas: HTMLCanvasElement,
    mobile: boolean,
    boxes: HTMLDivElement[],
    step: number,
    onPumpFinished?: VoidHandler
  ) {
    this.canvasWidth = canvas.width
    this.canvasHeight = canvas.height
    this.boxes = boxes
    this.mobile = mobile
    this.currentAmplitude = mobile ? 60 : 100
    this.amplitudeNormal = mobile ? 60 : 100
    this.amplitudeBig = mobile ? 160 : 200
    this.lineWidth = mobile ? 400 : 700
    this.pumpsCount = step
    this.context = canvas.getContext('2d')!
    this.startAnimating()
    this.onPumpFinished = onPumpFinished
  }

  private radians(
    a: number,
    xPosition: number,
    width: number,
    phase: number
  ): number {
    return (a * xPosition) / width + phase
  }

  private startAnimating() {
    this.interval = window.setInterval(() => {
      this.updateAnimation()
    }, 1000 / FPS_COUNT)
  }

  public stopAnimating() {
    window.clearInterval(this.interval)
  }

  private updateAnimation() {
    this.phase += this.phaseStep
    this.updateAmplitude()
    this.draw()
  }

  private updateAmplitude() {
    if (!this.isPumping) {
      return
    }

    if (this.currentAmplitude < this.amplitudeBig && this.amplitudeDelta > 0) {
      this.currentAmplitude += this.amplitudeDelta
    } else if (
      this.currentAmplitude >= this.amplitudeBig &&
      this.amplitudeDelta > 0
    ) {
      this.amplitudeDelta = -this.amplitudeDelta
      this.currentAmplitude += this.amplitudeDelta
    } else if (
      this.currentAmplitude > this.amplitudeNormal &&
      this.amplitudeDelta < 0
    ) {
      this.currentAmplitude += this.amplitudeDelta
    } else if (
      this.currentAmplitude <= this.amplitudeNormal &&
      this.amplitudeDelta < 0
    ) {
      this.amplitudeDelta = -this.amplitudeDelta
      this.currentAmplitude = this.amplitudeNormal
      this.isPumping = false
      this.pumpsCount += this.direction
      this.onPumpFinished?.()
    }
  }

  private draw() {
    this.context.clearRect(0, 0, this.canvasWidth, this.canvasHeight)
    this.drawLine()
    this.drawBoxes()
  }

  private calculateY(x: number) {
    const radians = this.radians(this.a, x, this.canvasWidth, this.phase)
    const height = this.canvasHeight / 2 + 250
    return Math.sin(radians) * this.currentAmplitude + height
  }

  private drawLine() {
    this.context.beginPath()
    // Move to the starting point off-screen to the left
    this.context.moveTo(-1000, this.canvasHeight / 2)
    // Draw the line
    for (let x = -OVERFLOW; x < this.canvasWidth + OVERFLOW; x += 20) {
      const y = this.calculateY(x)
      this.context.lineTo(x, y)
    }
    // Draw the line to the bottom-right corner of the canvas
    this.context.lineTo(this.canvasWidth + OVERFLOW, this.canvasHeight * 2)
    // Draw the line to the bottom-left corner of the canvas
    this.context.lineTo(-OVERFLOW, this.canvasHeight * 2)
    // Close the path to create a closed shape
    this.context.closePath()
    // Fill the area below the line with white color
    this.context.fillStyle = 'white'
    this.context.fill()
    // Stroke the line
    this.context.strokeStyle = 'white'
    this.context.lineWidth = this.lineWidth
    this.context.stroke()
  }

  private pumpWave(direction: -1 | 1) {
    if (this.isPumping) {
      return
    }
    this.phaseBeforePump = this.phase
    this.isPumping = true
    this.direction = direction
  }

  public accendingWave() {
    this.pumpWave(1)
  }

  public descendingWave() {
    this.pumpWave(-1)
  }

  private drawBoxes() {
    const shift = this.canvasWidth
    const phaseShift = this.isPumping ? this.phase - this.phaseBeforePump : 0
    const _shift = Math.round((phaseShift * shift) / 4)
    const deltaX = _shift > shift ? shift : _shift

    this.boxes.forEach((box, index) => {
      // Отступ слева у блока с иллюстрацией
      const baseDesiredX = this.mobile
        ? 0
        : this.canvasWidth / 2 - BOX_SIZE * 2 - BOX_SIZE + 50
      // Отступ слева у блока с иллюстрацией с учетом порядкового номера элемента и сдвига
      const desiredX = index * shift + baseDesiredX
      // Положение с учетом сдвигов после прокручивания при переходах между экранами
      const x = desiredX - this.pumpsCount * shift - deltaX * this.direction

      const translateX = Math.round(x / SCALE)
      const isInView =
        translateX > -BOX_SIZE && translateX < this.canvasWidth / SCALE

      if (!isInView) {
        box.style.display = 'none'
        return
      }

      const y = this.calculateY(x + BOX_SIZE / 2)
      const shiftY = this.mobile ? 120 : 190
      const translateY = y / SCALE - BOX_SIZE / 2 - shiftY

      box.style.display = 'flex'
      box.style.transform = `translate3d(${translateX}px, ${translateY}px, 0)`
    })
  }
}
