import React, { FC, SVGProps } from 'react'

export const PeriscopeReflectionSvg: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M68.3497 169.316C68.4901 190.519 91.159 194.243 102.476 193.454C111.332 194.229 129.642 191.761 132.033 175.695C134.424 159.629 122.043 156.61 115.554 157.109C114.388 157.139 107.549 157.013 89.5242 156.272C71.4995 155.531 67.8976 164.659 68.3497 169.316Z"
        fill="white"
      />
      <path
        d="M88.9279 165.338C85.7133 165.333 86.3032 167.02 87 167.865C95.0694 174.707 109.487 171.649 112.5 169.365C114.91 167.537 113.134 166.537 111.945 166.265C102.063 164.255 92.9463 165.345 88.9279 165.338Z"
        fill="#B89EFF"
      />
    </svg>
  )
}
