import React, { FC, SVGProps } from 'react'

export const AgeReflectionSvg: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M50.6233 167.411C50.717 188.616 80.3148 191.45 102.657 191.45C125 191.45 146.996 189.676 149.714 173.602C152.432 157.527 138.616 154.545 131.369 155.063C130.066 155.096 94.4304 154.991 74.3059 154.303C54.1813 153.616 50.1323 162.755 50.6233 167.411Z"
        fill="white"
      />
      <path
        d="M70.9149 162.367C64.7042 162.341 65.6725 164.263 66.9329 165.227C81.8286 173.056 128.46 169.483 134.513 166.905C139.356 164.842 136.026 163.694 133.755 163.378C114.866 161.031 78.6781 162.401 70.9149 162.367Z"
        fill="#FB9BF9"
      />
    </svg>
  )
}
