export const createDateFromStrings = (
  day: string,
  month: string,
  year: string
): Date | undefined => {
  if (!day || !month || !year) {
    return
  }

  const date = new Date(Number(year), Number(month) - 1, Number(day))

  // Проверим на валидность
  if (
    year.length < 4 ||
    date.getFullYear() !== Number(year) ||
    date.getMonth() + 1 !== Number(month) ||
    date.getDate() !== Number(day)
  ) {
    return
  }

  return date
}
