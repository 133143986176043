import loadable from '@loadable/component'
import surfSvg from 'components/designSystem/svg/surf.svg'
import { preloadImages } from 'functions/preloadImages'
import { useIsIosVersionWithSvgAnimationIssues } from 'hooks/useIsIosVersionWithSvgAnimationIssues'
import React, { FC, useEffect } from 'react'

export const SurfingGirlAnimation: FC = () => {
  const iosVersionWithSvgAnimationIssues = useIsIosVersionWithSvgAnimationIssues()

  useEffect(() => {
    if (!iosVersionWithSvgAnimationIssues) {
      preloadImages([surfSvg])
    }
  }, [iosVersionWithSvgAnimationIssues])

  if (iosVersionWithSvgAnimationIssues) {
    return (
      <div style={{ width: 160, height: 160 }}>
        <SurfingGirlAnimationLoadable />
      </div>
    )
  }

  return (
    <img
      src={surfSvg}
      width={200}
      height={200}
      draggable={false}
      style={{ userSelect: 'none' }}
    />
  )
}

const SurfingGirlAnimationLoadable = loadable(() =>
  import('./SurfingGirlAnimationLottie')
)
