import React, { FC, SVGProps } from 'react'

export const FlamingoReflectionSvg: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M64.6233 168.411C64.717 189.616 90.0184 193.272 102.657 192.45C112.544 193.198 132.996 190.676 135.714 174.601C138.432 158.527 124.616 155.545 117.369 156.063C116.066 156.096 108.43 155.991 88.3059 155.303C68.1813 154.616 64.1323 163.755 64.6233 168.411Z"
        fill="white"
      ></path>
      <path
        d="M78.8947 162.385C74.4418 162.381 75.2606 164.718 76.2266 165.887C87.4112 175.356 120.6 170.797 124.771 167.629C128.108 165.096 125.646 163.712 123.999 163.337C110.308 160.562 84.4609 162.391 78.8947 162.385Z"
        fill="#FFDED0"
      ></path>
    </svg>
  )
}
