import React, { FC, SVGProps } from 'react'

export const MailboxSvg: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M64.8931 49.5427C67.9158 52.051 74.6317 57.7458 77.3131 60.4587"
        stroke="white"
        strokeWidth="3.32558"
      />
      <path
        d="M64.8994 77.3803V96.7794C64.8994 99.2283 66.8846 101.214 69.3335 101.214H133.944C136.266 101.214 138.196 99.4226 138.309 97.1035C138.59 91.3215 138.756 82.9276 138.062 77.3803C136.954 68.5122 133.628 54.1011 121.434 55.764C109.24 57.4269 106.469 56.3183 85.4071 55.764C68.5575 55.3206 64.7146 69.9901 64.8994 77.3803Z"
        fill="#F88AC9"
      />
      <path
        d="M114.783 70.7292C113.009 80.0409 113.674 86.8026 113.674 93.4538L113.674 97.8879C113.674 99.7246 115.163 101.213 117 101.213H134.737C136.573 101.213 138.062 99.7245 138.062 97.8879V88.4654C138.062 66.2948 133.074 60.7525 128.64 57.4269C124.206 54.1013 117 59.0897 114.783 70.7292Z"
        fill="#8051FB"
      />
      <mask
        id="mask0_699_1195"
        maskUnits="userSpaceOnUse"
        x="113"
        y="56"
        width="26"
        height="46"
      >
        <path
          d="M114.783 70.7292C113.009 80.0409 113.674 86.8026 113.674 93.4538L113.674 97.8879C113.674 99.7246 115.163 101.213 117 101.213H134.737C136.573 101.213 138.062 99.7245 138.062 97.8879V88.4654C138.062 66.2948 133.074 60.7525 128.64 57.4269C124.206 54.1013 117 59.0897 114.783 70.7292Z"
          fill="#8051FB"
        />
      </mask>
      <g mask="url(#mask0_699_1195)">
        <path
          d="M118.579 75.7092C127.266 78.1777 131.1 87.6726 127.221 95.3223L126.675 96.4004C125.503 98.8394 122.74 100.343 120.092 99.7101C117.495 99.1035 115.859 97.1121 115.433 94.8958C114.068 95.1714 112.575 95.06 111.24 94.3832C107.749 92.6132 106.564 88.2045 108.873 84.9223C110.197 82.9476 112.649 81.9316 114.964 82.202C116.893 82.4702 118.329 83.4565 119.249 84.6973C119.508 84.4413 119.946 84.3408 120.357 84.549L122 85.382C122.41 85.5902 122.639 86.1579 122.405 86.6199L119.281 92.7807C118.708 93.9102 119.113 95.2768 120.293 95.8755C121.423 96.4482 122.816 95.9926 123.388 94.8632L123.805 94.0417C126.199 89.3184 124.869 83.2871 120.431 80.4563C115.301 77.2097 108.622 79.1803 105.941 84.4683C103.651 88.9862 105.036 94.4 109.062 97.28C109.78 97.7731 110.031 98.8043 109.641 99.5744L109.615 99.6257C109.12 100.601 107.806 100.903 106.908 100.254C100.703 95.946 99.0538 86.9783 104.11 80.4422C107.507 76.1621 113.309 74.2639 118.579 75.7092ZM112.829 91.1228C114.215 91.8256 115.866 91.2428 116.543 89.9079C117.245 88.5217 116.663 86.8709 115.328 86.1941C113.942 85.4913 112.317 86.0228 111.614 87.409C110.937 88.7439 111.443 90.42 112.829 91.1228Z"
          fill="#FFDED0"
        />
      </g>
      <path
        d="M128.627 112.589L115.366 103.091C114.49 102.464 114.934 101.082 116.011 101.082H136.539C136.809 101.082 137.075 101.129 137.325 101.229C144.991 104.306 153.497 112.425 149.135 115.915C144.701 119.462 134.724 117.023 128.627 112.589Z"
        fill="#F88AC9"
      />
      <path
        d="M95.9318 172.965V101.082H103.692V172.965H95.9318Z"
        fill="#B89EFF"
      />
      <path
        d="M133.061 141.155C133.65 144.838 139.089 151.916 147 159.9"
        stroke="#FF8640"
        strokeWidth="3.32558"
      />
      <path
        d="M127.708 139.614C129.727 140.897 133.48 138.758 136.165 140.442C138.85 142.126 138.402 144.582 137.414 145.894"
        stroke="#FFA26D"
        strokeWidth="3.32558"
        strokeLinecap="round"
      />
      <path
        d="M132.568 137.685C131.691 136.312 130.24 135.006 127.115 136.689C123.852 138.447 124.28 149.13 134.147 147.551"
        stroke="#FF8640"
        strokeWidth="3.32558"
        strokeLinecap="round"
      />
      <path
        d="M126.717 138.87C121.698 133.446 110.203 120.078 104.381 110"
        stroke="#FF8640"
        strokeWidth="3.32558"
        strokeLinecap="round"
      />
      <path
        d="M95.5463 106.624C94.8073 106.994 93.9943 108.509 96.6548 111.612C99.9804 115.492 104.969 117.155 104.415 112.721"
        stroke="#FF8640"
        strokeWidth="3.32558"
        strokeLinecap="round"
      />
      <path
        d="M93.1606 104.407C94.6386 104.037 99.0358 103.631 104.8 104.961"
        stroke="#FF8640"
        strokeWidth="3.32558"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.057 89.2242C117.944 83.8487 117.824 78.0845 119.225 70.7292C120.671 63.1341 124.242 58.371 127.781 56.9019C123.327 54.7485 116.862 59.8147 114.783 70.7292C113.382 78.0845 113.503 83.8487 113.615 89.2242C113.645 90.654 113.674 92.0563 113.674 93.4538L113.674 97.8879C113.674 99.7245 115.163 101.213 117 101.213H134.737C134.739 101.213 134.741 101.213 134.744 101.213H121.442C119.605 101.213 118.116 99.7245 118.116 97.8879L118.116 93.4538C118.116 92.0563 118.087 90.654 118.057 89.2242Z"
        fill="#8051FB"
        fillOpacity="0.54"
      />
      <path
        d="M64.8931 47.3177L70.8639 52.2738L66.398 57.064C65.5968 57.9233 64.2644 58.0085 63.3603 57.2581L60.8071 55.1387C59.8263 54.3247 59.7323 52.8532 60.6015 51.921L64.8931 47.3177Z"
        fill="white"
      />
    </svg>
  )
}
