import { VoidHandler } from 'common/types'

export const preloadImages = (
  imageUrls: string[],
  allImagesLoadedCallback?: VoidHandler
) => {
  let loadedImagesCount = 0
  const totalImages = imageUrls.length

  // This function will be called every time an image is loaded
  const imageLoaded = () => {
    loadedImagesCount++
    if (loadedImagesCount === totalImages) {
      allImagesLoadedCallback?.()
    }
  }

  // Loop through each image URL
  imageUrls.forEach((url) => {
    const img = new Image()
    img.src = url
    img.onload = imageLoaded
    img.onerror = imageLoaded // Handle the case where the image fails to load
  })
}
