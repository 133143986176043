import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import React, { FC } from 'react'
import { BoxesSwapper } from './BoxesSwapper'

export const AnimatedLetter: FC = () => {
  const { name } = useShallowEqualSelector(
    ({
      stepRegistration: {
        form: { name },
      },
    }) => ({ name })
  )

  return <BoxesSwapper value={name[0]?.toUpperCase()} defaultValue="N" />
}
