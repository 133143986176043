import React, { FC, SVGProps } from 'react'

export const MailboxReflectionSvg: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M68.3497 168.316C68.4901 189.519 91.159 193.243 102.476 192.454C111.332 193.229 129.642 190.761 132.033 174.695C134.424 158.629 122.043 155.61 115.554 156.109C114.388 156.139 107.549 156.013 89.5242 155.272C71.4995 154.531 67.8976 163.659 68.3497 168.316Z"
        fill="white"
      />
      <path
        d="M92 164.721C92 166.721 93.3333 167.554 94 167.721C97.5 169.721 101.5 169.221 105 168.221C108.5 167.221 108.5 162.721 104.5 162.221C101.3 161.821 97.5 162.054 96 162.221C94.6667 162.221 92 162.721 92 164.721Z"
        fill="#FFDED0"
      />
    </svg>
  )
}
