import React, { SVGProps } from 'react'

export const MambaLogoSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={199}
    height={44}
    viewBox="0 0 199 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_529_23264)">
      <path
        d="M34.8343 9.57715C31.2513 9.57715 27.3705 10.5666 23.7876 12.3576C20.8628 10.4433 17.7565 9.70046 14.4714 9.70046C9.93249 9.70046 5.15525 11.1215 0.5 12.9155V43.3214H13.0984V19.8358C14.7693 19.2192 17.0983 19.0313 17.0983 21.3802V43.3185H29.6967V19.8358C31.4272 19.2192 33.6967 18.9696 33.6967 21.3802V43.3185H46.3547V20.9486C46.3547 12.8538 41.1604 9.57715 34.8314 9.57715H34.8343Z"
        fill="#FF641F"
      />
      <path
        d="M193.184 12.111C189.72 12.111 187.751 9.57715 181.899 9.57715C171.987 9.57715 166.852 16.3742 166.852 26.8794C166.852 37.3847 171.987 43.9967 181.899 43.9967C184.943 43.9967 189.72 43.0073 193.184 43.0073C195.274 43.0073 197.064 43.0689 198.497 43.3156V11.3094C197.064 11.8027 195.271 12.1139 193.184 12.1139V12.111ZM185.839 35.3471C185.121 35.5937 184.466 35.6554 183.927 35.6554C181.002 35.6554 180.523 32.6283 180.523 27.2494C180.523 21.8705 181.059 18.7201 183.987 18.7201C184.523 18.7201 185.121 18.8434 185.836 19.0284V35.3441L185.839 35.3471Z"
        fill="#FF641F"
      />
      <path
        d="M154.254 9.57747C152.222 9.57747 149.834 9.8241 147.147 11.3068V0H134.489V43.3218C140.818 42.4556 143.925 44.0029 148.94 44.0029C160.523 44.0029 164.824 38.3187 164.824 26.0811C164.824 17.8014 161.598 9.58041 154.254 9.58041V9.57747ZM149.059 35.6557C148.523 35.6557 147.865 35.594 147.147 35.3474V19.0316C147.865 18.8467 148.461 18.7233 148.997 18.7233C151.922 18.7233 152.461 21.7504 152.461 27.2526C152.461 32.7548 151.984 35.6586 149.056 35.6586L149.059 35.6557Z"
        fill="#FF641F"
      />
      <path
        d="M64.031 9.57715C54.1191 9.57715 48.9844 16.3742 48.9844 26.8794C48.9844 37.3847 54.1191 43.9967 64.031 43.9967C67.0749 43.9967 71.8521 43.0073 75.3159 43.0073C77.4067 43.0073 79.1967 43.0689 80.6293 43.3156V11.3094C79.1967 11.8027 77.4038 12.1139 75.3159 12.1139C71.8521 12.1139 69.8834 9.58009 64.031 9.58009V9.57715ZM67.9713 35.3471C67.2536 35.5937 66.5983 35.6554 66.0593 35.6554C63.1345 35.6554 62.6551 32.6283 62.6551 27.2494C62.6551 21.8705 63.1913 18.7201 66.1189 18.7201C66.655 18.7201 67.2536 18.8434 67.9685 19.0284V35.3441L67.9713 35.3471Z"
        fill="#FF641F"
      />
      <path
        d="M118.966 9.57715C115.383 9.57715 111.502 10.5666 107.919 12.3576C104.995 10.4433 101.888 9.70046 98.6033 9.70046C94.0643 9.70046 89.2871 11.1215 84.6318 12.9155V43.3214H97.2302V19.8358C98.9011 19.2192 101.23 19.0313 101.23 21.3802V43.3185H113.829V19.8358C115.559 19.2192 117.829 18.9696 117.829 21.3802V43.3185H130.486V20.9486C130.486 12.8538 125.292 9.57715 118.963 9.57715H118.966Z"
        fill="#FF641F"
      />
    </g>
    <defs>
      <clipPath id="clip0_529_23264">
        <rect width={198} height={44} fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
)
