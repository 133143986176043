import React, { FC, SVGProps } from 'react'

export const PeriscopeSvg: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M90.0898 111.263V177.41H108.317V111.263C108.317 96.6918 121.747 92.406 128.462 92.7256L135 71H128.462C95.4618 71 89.1305 97.8422 90.0898 111.263Z"
        fill="#B095F9"
      />
      <path
        d="M124.323 90.553C125.92 91.6284 127.575 93 129.5 93C131.425 93 133.08 91.6285 134.677 90.5531C137.426 88.7011 141.524 85.162 141.943 80.313C142.577 72.9487 137.729 71.501 137.729 71.501C131.872 69.4763 129.982 74.1316 129.5 75.8249C129.018 74.1316 127.128 69.4763 121.271 71.501C121.271 71.501 116.423 72.9487 117.057 80.3129C117.476 85.1619 121.573 88.701 124.323 90.553Z"
        fill="#432A86"
      />
    </svg>
  )
}
