import React, { FC, SVGProps } from 'react'

export const NameLetterReflexSvg: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M64.6233 167.411C64.717 188.616 90.0184 192.272 102.657 191.45C112.544 192.198 132.996 189.676 135.714 173.601C138.432 157.527 124.616 154.545 117.369 155.063C116.066 155.096 108.43 154.991 88.3059 154.303C68.1813 153.616 64.1323 162.755 64.6233 167.411Z"
        fill="white"
      ></path>
      <path
        d="M85.3536 162.276C82.3795 162.256 82.8432 163.697 83.4468 164.421C90.5799 170.292 112.91 167.612 115.809 165.679C118.128 164.132 116.533 163.271 115.446 163.033C106.401 161.273 89.0712 162.3 85.3536 162.276Z"
        fill="#FB9BF9"
      ></path>
    </svg>
  )
}
