import React, { FC } from 'react'

export const FlamingoSvg: FC = () => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.5586 127.448C54.5586 127.448 51.1041 112.198 65.7662 106.304C80.4284 100.41 112.024 96.8025 135.187 108.143C158.35 119.483 150.285 138.918 143.253 143.677C138.68 146.772 127.821 152.755 84.7648 148.751C84.7648 148.751 58.3579 149.028 54.5586 127.448Z"
        fill="#F88AC9"
      ></path>
      <path
        d="M87.1384 109.383C87.1384 109.383 107.223 105.057 120.352 113.069C133.481 121.082 101.298 117.858 101.298 117.858C101.298 117.858 74.5223 114.156 87.1384 109.383Z"
        fill="#9A77F6"
      ></path>
      <path
        d="M86.5135 143.8C86.5135 143.8 82.217 139.027 85.7815 130.758C89.3459 122.489 106.61 120.465 124.786 133.529C142.962 146.592 119.151 150.402 119.151 150.402C119.151 150.402 92.2679 154.124 86.5152 143.802L86.5135 143.8Z"
        fill="#FBB9DF"
      ></path>
      <path
        d="M70.0253 81.2156C70.0253 81.2156 59.4718 75.3969 61.7396 61.904C64.0073 48.4111 75.3871 44.1664 84.5346 46.5368C93.682 48.9071 108.079 59.0476 91.5363 83.9327C91.5363 83.9327 78.675 101.314 78.0066 109.903C78.0066 109.903 77.6214 111.352 76.1764 111.566C74.7315 111.781 61.0599 111.807 61.0599 111.807C61.0599 111.807 58.9531 111.272 59.2268 109.509C59.5005 107.746 60.1028 99.0745 77.7865 79.997C77.7865 79.997 88.245 69.37 85.8468 60.1347C85.8468 60.1347 84.8545 55.6892 81.4975 56.2839C78.1404 56.8787 75.5398 60.7693 74.8631 68.7618C74.1864 76.7543 76.4317 84.7024 70.0253 81.2156Z"
        fill="#F88AC9"
      ></path>
      <path
        d="M70.0251 81.2158C76.4319 84.6987 74.1861 76.7545 74.8629 68.762C75.267 63.9695 76.3659 60.6561 77.9026 58.6381C75.0192 55.5158 69.9923 52.2555 67.3621 50.6579C64.6339 53.2483 62.5662 56.9528 61.735 61.9077C59.4712 75.401 70.0207 81.2192 70.0207 81.2192L70.0251 81.2158Z"
        fill="white"
      ></path>
      <path
        d="M77.4726 59.2565C72.9084 56.1515 67.741 54.705 64.5898 54.0688C63.2529 56.2164 62.2577 58.8191 61.7361 61.9038C59.4723 75.3971 70.0218 81.2153 70.0218 81.2153C76.4287 84.6983 74.1829 76.7541 74.8596 68.7616C75.2234 64.4328 76.158 61.3113 77.4682 59.2599L77.4726 59.2565Z"
        fill="#432A86"
      ></path>
      <path
        d="M72.8206 54.5185C73.9808 54.649 75.0271 53.8143 75.1576 52.6541C75.2881 51.4939 74.4533 50.4476 73.2932 50.3171C72.133 50.1866 71.0867 51.0213 70.9562 52.1815C70.8257 53.3417 71.6604 54.388 72.8206 54.5185Z"
        fill="#1A0609"
      ></path>
    </svg>
  )
}
