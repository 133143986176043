import React, { FC, SVGProps } from 'react'

export const GullReflectionSvg: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M64.6233 167.411C64.717 188.616 90.0184 192.272 102.657 191.45C112.544 192.198 132.996 189.676 135.714 173.601C138.432 157.527 124.616 154.545 117.369 155.063C116.066 155.096 108.43 154.991 88.3059 154.303C68.1813 153.616 64.1323 162.755 64.6233 167.411Z"
        fill="white"
      />
      <path
        d="M83.6939 161.991C80.6757 162.056 81.2665 163.627 81.9391 164.405C89.6648 170.652 112.09 167.053 114.868 164.842C117.091 163.074 115.401 162.174 114.279 161.945C104.957 160.274 87.4666 161.91 83.6939 161.991Z"
        fill="#B89EFF"
      />
    </svg>
  )
}
