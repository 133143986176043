import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

/** На iOS меньше 16-й версии включительно не работает svg анимация, реализованная с помощью кривых Безье */
export const useIsIosVersionWithSvgAnimationIssues = () => {
  const iosVersion = useShallowEqualSelector(
    ({ systemReducer: { iosVersion } }) => iosVersion
  )

  if (!iosVersion) {
    return false
  }

  return iosVersion <= 16
}
