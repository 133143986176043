import { styled } from '@linaria/react'
import { breakpoints } from 'components/designSystem/shared/breakpoints'
import { applyIfAnimationsEnabledCss } from 'components/designSystem/styles/applyIfAnimationsEnabledCss'
import { fadeInCss } from 'components/designSystem/styles/fadeInCss'

export const OnboardingLayoutWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  @media screen and (max-width: ${breakpoints.mobile - 1}px) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  @media (min-width: ${breakpoints.mobile}px) {
    height: 100%;
    padding-top: var(--spacing-48px, 48px);
    padding-bottom: var(--spacing-48px, 48px);
  }
  display: flex;
  flex-direction: column;
  align-items: center;

  ${applyIfAnimationsEnabledCss(fadeInCss)};
`
export const OnboardingLayoutInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  @media (min-width: ${breakpoints.mobile}px) {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 560px;
    max-height: 796px;
    flex-shrink: 0;
    border-radius: 50px;
    border: 2px solid var(--border-default, rgba(127, 116, 114, 0.48));
  }
  @media screen and (min-width: ${breakpoints.mobile}px) and (max-height: 900px) {
    max-height: 720px;
  }
`
